<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title v-if="false">
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <template>
                  <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 10 : 1"
                        :class="[
                          { 'on-hover': hover },
                          hover
                            ? 'card-hover-opacity'
                            : 'card-not-hover-opacity',
                        ]"
                      >
                        <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          height="225px"
                        >
                          <v-card-title class="text-h6 white--text fill-height">
                            <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                            >
                              <v-spacer />
                              <div class="align-self-center">
                                <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                                >
                                  <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                  >
                                    mdi-upload
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="itemModified.nip"
                    label="NIP"
                    :rules="rulesNotEmtpy"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field
                    v-model="itemModified.description"
                    :rules="rulesNotEmtpy"
                    label="Nama ASN"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="itemModified.periodeMonth"
                    label="Periode Bulan"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="itemModified.periodeYear"
                    label="Tahun"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.ftemplateRegBean"
                    :items="itemsFTemplateReg"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Jenis Pengajuan/Usulan"
                    hint="Jenis Pengajuan/Usulan"
                    persistent-hint
                    single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Pemilik Data"
                    hint="Pemilik Data"
                    persistent-hint
                    dense
                    single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down mr-10"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FtRegistrasiService from "../../services/apiservices/ft-registrasi-service";

import CloseConfirmDialog from "../../components/utils/CloseConfirmDialog";
import FormMode from "../../models/form-mode";
import FtRegistrasi from "../../models/ft-registrasi";
import FileService from "../../services/apiservices/file-service";
import UploadImageDialog from "../../components/utils/UploadImageDialog";

export default {
  name: "ValidasiDetilDialog",
  components: { CloseConfirmDialog, UploadImageDialog },
  props: {
    formMode: String,
    itemsFDivision: [],
    itemsFTemplateReg: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  data() {
    return {
      title: "VALIDASI",
      snackBarMesage: "",
      snackbar: false,

      dialogLoading: false,
      loading: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FtRegistrasi(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  watch: {
  },

  methods: {
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== undefined &&
        this.itemModified.description !== undefined &&
        this.itemModified.fdivisionBean !== undefined
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage = "Kode, Deskripsi dan Divisi harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)

      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          () => {
            // console.log(response.data)
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)

      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        this.itemDefault = new FtRegistrasi(0, "", "");
        this.itemModified = new FtRegistrasi(0, "", "");
        this.selectedIndex = -1;
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FtRegistrasiService.createFtRegistrasi(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FtRegistrasiService.createFtRegistrasi(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          // this.initializeEditMode(response.data)
          this.$emit("update:formMode", FormMode.EDIT_FORM);
          this.itemModified.id = response.data.id;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.formDialogOptions.errorMessage = "";

      FtRegistrasiService.getFtRegistrasiById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFInfratructureType(finfratructureTypeBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === finfratructureTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFSubArea(fsubAreaBean) {
      const str = this.itemsFSubArea.filter((x) => x.id === fsubAreaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFArea(fareaBean) {
      const str = this.itemsFArea.filter((x) => x.id === fareaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))

      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>