<template>
    <v-card class="mt-4 mx-2 elevation-0">
      <FtRegistrasiTable></FtRegistrasiTable>
    </v-card>
</template>
  
<script>

  import FtRegistrasiTable from "@/components/admin_validasi/ValidasiTable.vue";

  export default {
    name: "ValidasiView",
    components: {
      FtRegistrasiTable,
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {

    },
  
  
  }
  </script>
  
  <style scoped>
  </style>