<template>
  <v-dialog
    v-model="dialogShow"
    :max-width="myConfirmDeleteOptions.width"
    :style="{ zIndex: myConfirmDeleteOptions.zIndex }"
    @keydown.esc="dialogShow = false"
  >
    <v-card>
      <v-toolbar dark :color="myConfirmDeleteOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ myConfirmDeleteOptions.title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-title class="subtitle-1"
        >{{ myConfirmDeleteOptions.message1 }}
      </v-card-title>
      <v-card-title class="subtitle-1 font-weight-bold"
        >{{ myConfirmDeleteOptions.message2 }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" outlined text @click="dialogShow = false"
          >Cancel</v-btn
        >
        <v-btn color="success" outlined text @click="okeConfirmed"
          ><v-icon small>mdi-save</v-icon> OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "DeleteDialog",
  data() {
    return {
      selectedItems: [],
      selectedIndex: -1,
      itemSelected: "",
      dialogShow: false,
      myConfirmDeleteOptions: {
        title: "Konfirmasi Verifikasi",
        message1: "Yakin akan Mengambil Verifikasi Usulan?",
        message2: "",
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  props: [],
  computed: {},
  methods: {
    okeConfirmed: function () {
      // this.myConfirmDelete = false
      //   console.log(this.selectedItems);
      if (this.selectedIndex > -1) {
        this.$emit(
          "eventFromVerifikatorConfirmDialog",
          this.selectedIndex,
          this.itemSelected
        );
      }
    },
    showDialog(selectedIndex, itemSelected, message) {
      // console.log("from parent " + message)
      if (selectedIndex > -1) {
        this.myConfirmDeleteOptions.message2 = message;
        this.selectedIndex = selectedIndex;
        this.itemSelected = itemSelected;
        this.selectedItems = [];
        this.dialogShow = !this.dialogShow;
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
  },
};
</script>
  
  <style scoped>
</style>